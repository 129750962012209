import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IsMobileContext, LanguageContext } from '../common/Context'

// components
import Navbar from '../components/Navbar'
import Link from '../components/Link'

// hooks, models
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'

import withSSR from '../hoc/withSSR'
import './404.scss'

const Page404: React.FC<PageProps> = ({ location: { pathname } }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components.  */
  const pageData = {
    linksGridData: useLinksGridData(language, 'home_page'),
    navbarData: useNavbarData(language, 'home_page', pathname),
  }

  return (
    <Layout seoData={{}} language={language} setLanguage={setLanguage}>
      <Page404TemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface Page404Props {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
  }
}

const Page404Template: React.FC<Page404Props> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
  }
}) => {
  const { t } = useTranslation()

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="Page404 mx-m grid grid-cols-12 gap-x-m">
        <div className="col-span-12 lg:col-span-6 grid grid-cols-12 lg:grid-cols-6 gap-x-m">
          <div className="col-span-12 lg:col-span-6 pt-mobile-l lg:pt-l"></div>
          <div className="col-span-12 lg:col-span-6 FontXXL pt-mobile-l lg:pt-xl pb-mobile-s lg:pb-s">
            404
          </div>
          <div className="col-span-12 lg:col-span-5 FontL pb-mobile-l lg:pb-xl">
            {t('page404:weAreNotSorry')}
          </div>
          <div className="col-span-9 lg:col-span-6 FontS flex flex-wrap">
            <span className="mr-mobile-xs lg:mr-xs mt-mobile-xxs lg:mt-xxs">
              {t('page404:aFewLinks')}
            </span>
            <span className="mt-mobile-xxs lg:mt-xxs">
              <Link url="/" title={t('page404:home')} />
              <Link url="/blog" title={t('page404:blog')} className="ml-mobile-xs lg:ml-xs" />
              <Link url="/contact" title={t('page404:contact')} className="ml-mobile-xs lg:ml-xs" />
            </span>
          </div>
        </div>
      </main>
    </IsMobileContext.Provider>
  )
}
const Page404TemplateWithSSR = withSSR<Page404Props>(Page404Template)

export default Page404

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","page404"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
